import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { UserProfile } from "../setting.type";

export const settingActions = createActionGroup({
  source: "setting",
  events: {
    getUserProfile: emptyProps(),
    getUserProfileSuccess: props<{ userProfile: UserProfile }>(),
    getUserProfileFailure: props<{ error: string }>(),
  },
});
