import { createFeature, createReducer, on } from "@ngrx/store";
import { UserProfile } from "../setting.type";
import { settingActions } from "./setting.action";

export type SettingState = {
  userProfile: UserProfile | null;
  isLoading: boolean;
  error: string | null;
};
export const initialState: SettingState = {
  userProfile: null,
  isLoading: false,
  error: null
}

export const settingFeature = createFeature({
  name: "setting",
  reducer: createReducer(
    initialState,
    on(settingActions.getUserProfile, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }),
    on(settingActions.getUserProfileSuccess, (state, { userProfile }) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        userProfile,
      };
    }),
    on(settingActions.getUserProfileFailure, (state, { error }) => {
      return {
        ...state,
        isLoading: false,
        error,
      };
    }),
  )
});

export const { name, reducer, selectUserProfile } = settingFeature;